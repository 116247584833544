//
//
// navigation.js
//
// Manage classes on nav bar when toggled

import jQuery from 'jquery';

(($) => {
  const Event = {
    TOGGLE_SHOW: 'show.bs.collapse',
    TOGGLE_HIDE: 'hide.bs.collapse',
  };

  const Selector = {
    CONTAINER: 'body > div.navbar-container',
    NAV: '.navbar-container > .navbar',
  };

  const ClassName = {
    TOGGLED_SHOW: 'navbar-toggled-show',
    VISIBLE: 'd-block',
    HIDDEN: 'd-none'
  };

  const container = document.querySelector(Selector.CONTAINER);
  const nav = document.querySelector(Selector.NAV);
  const navbarLogoLight = document.querySelector(".navbar-logo-light");
  const navbarLogoDark = document.querySelector(".navbar-logo-dark");
  $(container).on(`${Event.TOGGLE_SHOW} ${Event.TOGGLE_HIDE}`, (evt) => {
    const action = `${evt.type}.${evt.namespace}` === Event.TOGGLE_SHOW ? 'add' : 'remove';
    nav.classList[action](ClassName.TOGGLED_SHOW);
  });
  // PJOTR: Add listeners for changing logo color
  $(container).on(`${Event.TOGGLE_SHOW} ${Event.TOGGLE_HIDE}`, (evt) => {
    const action = `${evt.type}.${evt.namespace}` === Event.TOGGLE_SHOW ? 'add' : 'remove';
    navbarLogoLight.classList[action](ClassName.HIDDEN);
    navbarLogoDark.classList[action](ClassName.VISIBLE);
  });
})(jQuery);
